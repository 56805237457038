/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html {
    overflow-x: hidden;
}

body {
    line-height: 1;
    font-size: 18px;
    background: #070707 0% 0% no-repeat padding-box;
    overflow-x: hidden;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

div {
    box-sizing: border-box;
}

main {
    position: relative;
    z-index: 3;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-button {
    background: #ccc
}

::-webkit-scrollbar-track-piece {
    background: #888
}

::-webkit-scrollbar-thumb {
    background: #eee
}

​

/* Override default styles for anchor tags */
a {
    text-decoration: none;
    /* Remove underline by default */
}

@keyframes slide-in {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@-webkit-keyframes slide-in {
    0% {
        -webkit-transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes slide-out-left {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(100%);
    }
}

@mixin button_mint_modal {
    padding: 10px 30px 10px 50px;
    margin: 20px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    font-weight: bold;
    color: #2081E2;
    border: 1px solid #2081E2;
    background: black;
    text-decoration: none;
    position: relative;
    max-width: 360px;
}

.slide-in {
    animation: slide-in .5s forwards;
    -webkit-animation: slide-in .5s forwards;
}

.slide-in-left {
    animation: slide-in-left .5s forwards;
    -webkit-animation: slide-in-left .5s forwards;
}

.slide-out {
    animation: slide-out .5s forwards;
    -webkit-animation: slide-out .5s forwards;
}

.slide-out-left {
    animation: slide-out-left .5s forwards;
    -webkit-animation: slide-out-left .5s forwards;
}

.banner {
    padding-top: 110px;

    img {
        border-radius: 24px;
    }

    @media only screen and (max-width: 720px) {
        padding-top: 150px;

        img {
            border-radius: 12px;
        }
    }
}

.button {
    padding: 0;
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    border: none;
}

.cpc_app {
    .Toastify__progress-bar--wrp {
        display: none;
    }

    .Toastify__toast-container .bg-red-600 {
        background: #541e1b;
        border-radius: 10px;
    }

    .Toastify__toast-container .bg-blue-600 {
        background: #A9F000;
        border-radius: 10px;
    }

    .custom_toaster {
        position: relative;
        padding: 20px 20px 20px 40px;
    }

    .Toastify__close-button {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    .Toastify__toast-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .text-sm {
        font-size: 16px;
        font-weight: 600;
    }

    .bg-red-600 .text-sm {
        font-size: #fff;
    }

    .bg-blue-600 .text-sm {
        color: #17181A;
    }

    .bg-blue-600 .Toastify__toast-icon svg *,
    .bg-blue-600 .Toastify__close-button svg * {
        fill: #17181A;
    }

    .bg-red-600 .Toastify__toast-icon svg *,
    .bg-red-600 .Toastify__close-button svg * {
        fill: #fff;
    }
}

.green {
    color: #A9F000;
}

.blue {
    color: #2190FF;
}

.gray {
    color: #A5A5A5;
}

.money {
    font-family: TiktokDisplay;
    font-size: 48px;
    line-height: 90px;
}

.header_container {
    display: flex;
    align-items: center;
    padding: 20px 0;
    justify-content: space-between;

    @media only screen and (max-width: 720px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        align-items: center;
        justify-items: center;
    }

    @media only screen and (max-width: 360px) {
        >svg {
            max-width: 80%;
        }
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotate(180deg);
    z-index: 1;

    &.bottom {
        transform: rotate(0deg);
    }
}

.App-header {
    background: #070707;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.no-display {
    transform: translateX(-100vw);
}

.tracker {
    // display: flex;
    align-items: center;
    z-index: 4;
    overflow-x: hidden;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    min-height: 1000px;
}

.tracker figure {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.multi_color_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    background: transparent linear-gradient(99deg, #A9F000 0%, #FF9900 100%) 0% 0% no-repeat padding-box;
    border-radius: 28px;
    min-width: 300px;
    gap: 10px;
    font-weight: bold;
    font-size: 28px;

    &:hover {
        transition: all 1s;
        transform: scale(0.9);
    }

    svg * {
        fill: #101214;
        stroke: #101214;
    }

    @media only screen and (max-width: 360px) {
        width: 100%;
        min-width: auto;
    }
}

.multi_color_btn.main_button {
    box-sizing: border-box;
    border: none;
    padding: 10px;
    min-width: 180px;
    font-size: 16px;
    color: #101214;
    cursor: pointer;

    @media only screen and (max-width: 360px) {
        font-size: 14px;
    }
}

.button_wrapper {
    position: relative;
    overflow: visible;
}

.button_wrapper::after {
    content: '';
    clear: both;
}

.button_dropdown {
    display: flex;
    position: absolute;
    width: 182px;
    top: 63px;
    right: 382px;
    padding: 10px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background: #191B1D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    font-size: 12px;
    transition: all 1s;
    z-index: 10000;
    color: #A4A4A4;
    cursor: pointer;

    svg path {
        fill: #fff;
    }

    &:hover {
        svg * {
            fill: #A9F000;
        }

        color: #A9F000;
    }

    @media only screen and (max-width: 1365px) {
        right: 220px;
    }

    @media only screen and (max-width: 720px) {
        top: 124px;
        right: auto;
        left: 50%;
        transform: translateX(calc(-100% - 5px));
    }

    @media only screen and (max-width: 420px) {
        left: calc(50% - 68px);
        transform: translateX(calc(-50%));
        ;
    }

    @media only screen and (max-width: 365px) {
        left: calc(50% - 38px);
        transform: translateX(calc(-50% - 25px));
    }
}

.multi_color_btn.main_button:hover {
    transform: none;
}

.multi_color_btn.main_button svg {
    margin-right: 5px;
}

.header_button {
    display: flex;
    gap: 10px;
    align-items: center;
}

.subcription {
    max-width: 1200px;
    margin: -40px auto 0;

}

.card {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 10px;
    padding: 20px;
    background: #17181A 0% 0% no-repeat padding-box;
    border-radius: 18px;

    p {
        line-height: 24px;
    }

    &__header {
        font-size: 20px;
        letter-spacing: 0px;
        color: #A9F000;
        margin-bottom: 10px;
        line-height: 30px;
        font-weight: bold;

        &+p {
            font-size: 16px;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 39px;
        height: 39px;
        border-radius: 50%;
        /* UI Properties */
        background: rgba(255, 255, 255, 0.17);

        svg * {
            fill: #A9F000;
            stroke: #A9F000;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        margin: 50px 0;

        &.big_gap {
            gap: 30px;
        }

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            gap: 16px;

            &.big_gap {
                gap: 16px;
            }
        }
    }

    &__image_box {
        display: grid;
        grid-template-columns: 400px 1fr;
        gap: 50px;

        .smaller {
            font-size: 16px;
        }

        .mt_30.multi_color_btn {
            margin-top: 80px;
        }

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 20px;
        }

        .multi_color_btn {
            margin-top: 30px;
        }

        p {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0;
            margin: 0 0 20px 0;
            line-height: 20px;

            svg {
                fill: #A9F000;
                stroke: #A9F000;
                width: 16px;
                height: 16px;
                transform: rotate(90deg);
            }

            svg path {
                fill: #A9F000;
                stroke: #A9F000;
            }
        }
    }

    &__image {
        border-radius: 12px;
        background: #17181A 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(to right, #17181A, #4F4C4B, #17181A);
    }

    &__image img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
    }

    &__item {
        position: relative;
    }

    &_image {
        z-index: 13;
        position: relative;
    }

    &_content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        padding: 100px 40px 40px;
        position: relative;
        top: - 130px;
        background: #131314 0% 0% no-repeat padding-box;
        border-radius: 18px;
        z-index: 12;

        p {
            padding: 0;
            margin-bottom: 14px;
        }

    }
}

.container {
    padding: 0 194px;
    margin: 0 auto;
    overflow-x: hidden;
    z-index: 4;

    img {
        max-width: 100%;

    }

    @media only screen and (max-width: 1365px) {
        padding: 0 30px;
    }
}

.title {
    font-family: TikTokDisplay;
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 32px;
    line-height: 36px;
    text-transform: capitalize;

    &.normal {
        font-size: 28px;
        line-height: 36px;
        font-weight: bold;
    }

    &.small {
        font-size: 24px;
        font-weight: bold;
        display: flex;
        gap: 5px;
        align-items: center;
    }
}

.main_button {
    width: 180px !important;
    height: 40px;
    /* UI Properties */
    font-size: 16px;
    border: 1px solid #A9F000;
    letter-spacing: 0px;
    color: #A9F000;
    border-radius: 6px;
    text-transform: capitalize;
    background-color: transparent;
    font-weight: bold;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    @media only screen and (max-width: 420px) {
        width: auto !important;
        padding: 0 15px;
        max-width: 180px;
    }

    @media only screen and (max-width: 360px) {
        padding: 0 10px;
        font-size: 14px;
    }
}

.text_clipped {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0 40px;
    gap: 4px;
    max-width: 254px;
    background: #191B1D 0% 0% no-repeat padding-box;
    border-radius: 20px;
}

.switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #191B1D 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 10px 30px;
    color: #FFFFFF;
    text-transform: capitalize;
    opacity: 0.37;
    font-weight: bold;
    transition: all 1s;

    &.active {
        background: #A9F000 0% 0% no-repeat padding-box;
        color: #17181A;
        text-transform: capitalize;
        opacity: 1;
    }
}

.info_box {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* UI Properties */
    background: #191B1D 0% 0% no-repeat padding-box;
    border-radius: 0px 6px 6px 0px;

}

.grid_info {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 380px;
    gap: 0;
    margin: 30px 0;

    .info_box:nth-child(2) {
        border-radius: 0;
        border-right: 1px solid #313131;
    }

    .info_box:nth-child(1) {
        border-radius: 6px 0px 0px 6px;
        border-right: 1px solid #313131;
    }
}

.grid_container .MuiGrid-root {
    /* position: relative; */
    display: flex;
    align-items: stretch;
}

.grid_container .MuiGrid-root .MuiPaper-root {
    /* position: absolute; */
    display: flex;
    align-items: stretch;
    width: 100%;
}

.small_txt {
    font-size: 20px !important;
}

.modal {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.85);
    transition: opacity 0.3s ease-in-out;
}

.modal-content {
    background-color: #17181A;
    margin: 15% auto;
    padding: 0 40px 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 420px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);

    p {
        font-size: 14px;
        color: #cccccc;
        margin: 20px 0;
        word-break: break-all;
        line-height: 20px;
    }

    .green {
        font-size: 22px;
        line-height: 32px;
        font-weight: bold;
    }

    .logo_bottom {
        margin: 0;

        svg {
            max-width: 160px;
        }
    }

    .logo_modal {
        margin: 0;

        img {
            max-width: 120px;
        }
    }

    .hash {
        margin: 20px 0 0;

        +p {
            margin-top: 10px;
        }
    }

    @media only screen and (max-width: 1200px) {
        transform: translateX(-50%);
        top: 40px;
    }

    @media only screen and (max-width: 420px) {
        padding: 0 15px;
    }

    @media only screen and (max-width: 360px) {
        width: calc(100% - 30px);
    }
}

.open_see_button {
    @include button_mint_modal;

    svg {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -1px;
        left: 0;
    }

    &:hover {
        background: #2081E2;
        color: #fff;
    }

    @media only screen and (max-width: 520px) {
        text-indent: -9999px;
        line-height: 0;

        &:after {
            content: 'Open Open Sea';
            text-indent: 0;
            display: block;
            line-height: initial;
        }

        svg {
            top: -1px;
            width: 42px;
            height: 42px;
        }
    }
}


.modal.open {
    display: block;
}

.modal.open .modal-content {
    opacity: 1;
}

.close {
    color: #aaa;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}